import { figmaHelper } from "../../styles/typography";
import type { SingleStyleOverride } from "../../styles/types";
import type { SystemStyleObject, ThemingProps } from "@chakra-ui/react";

const commonHeadingStyles = {
	em: {
		fontStyle: "inherit",
		color: "brand.highlight",
	},
	hyphens: "manual",
};

const h2: SystemStyleObject = {
	fontFamily: "sans",
	fontWeight: "semibold",
	color: "brand.blue",
	...figmaHelper({ base: 24, lg: 42 }, { base: 32, lg: 52 }),
	...commonHeadingStyles,
};

const h3: SystemStyleObject = {
	fontFamily: "sans",
	fontWeight: "semibold",
	...figmaHelper({ base: 20, lg: 30 }, { base: 26, lg: 40 }),
	...commonHeadingStyles,
};

const h4: SystemStyleObject = {
	fontFamily: "sans",
	fontWeight: "semibold",
	...figmaHelper({ base: 18, lg: 24 }, { base: 22, lg: 32 }),
	...commonHeadingStyles,
};

const h5: SystemStyleObject = {
	fontFamily: "sans",
	fontWeight: "semibold",
	...figmaHelper({ base: 16, lg: 18 }, { base: 18, lg: 26 }),
	...commonHeadingStyles,
};

const h6: SystemStyleObject = {
	fontFamily: "sans",
	fontWeight: "semibold",
	...figmaHelper({ base: 14, lg: 16 }, { base: 16, lg: 20 }),
	...commonHeadingStyles,
};

const hero: SystemStyleObject = {
	fontFamily: "serif",
	fontStyle: "italic",
	fontWeight: "normal",
	letterSpacing: "-0.01em",
	...figmaHelper({ base: 24, lg: 64 }, { base: 32, lg: 75 }),
};

export const sizes = {
	h2,
	h3,
	h4,
	h5,
	h6,
	hero,
};

const Heading: SingleStyleOverride<ThemingProps<"Heading">> = {
	sizes,
};

export default Heading;
