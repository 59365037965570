import { SystemStyleObject } from "@chakra-ui/react";
import { pxToRem } from "src/styles/themeUtils";
import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const side: SystemStyleObject = {
	position: "absolute",
	top: 0,
	left: 0,
	width: "full",
	height: "full",
	padding: 2,
	paddingY: 12,
	backfaceVisibility: "hidden",
	borderRadius: "md",
	boxShadow: "drop",
	bg: "brand.lighter",
	overflow: "hidden",
};

const Flipper: ComponentStyleConfig = {
	baseStyle: ({ isFlipped }) => {
		return {
			wrapper: {
				perspective: "2000px",
				textAlign: "center",
				width: "100%",
				maxW: 480,
				hyphens: "auto",
			},
			inner: {
				position: "absolute",
				top: 0,
				left: 0,
				width: "full",
				height: "full",
			},
			cards: {
				width: "full",
				height: "full",
				transform: isFlipped ? "rotateY(180deg)" : "rotateY(0)",
				transformStyle: "preserve-3d",
				transition: "transform 400ms ease-out",
			},
			front: {
				...side,
			},
			back: {
				...side,
				outlineColor: "brand.blue",
				outline: "1px solid",
				transform: "rotateY(180deg)",
				display: "flex",
				flexFlow: "column nowrap",
				justifyContent: "center",
				alignItems: "center",
			},
			moreInfo: {
				color: "brand.blue.darker",
				opacity: "0.5",
				width: "1.375rem",
				height: "1.375rem",
				border: "1.5px solid currentColor",
				borderRadius: "full",
				fill: "transparent",
				fillRule: "none",

				position: "absolute",
				top: 3,
				right: 3,
			},
			iconWrapper: {
				position: "relative",
				width: pxToRem(60),
				height: pxToRem(60),
				border: "2px solid",
				borderColor: "brand.blue",
				borderRadius: "full",
				margin: "auto",
				background: "brand.highlight",
				mb: 4,
			},
			icon: {
				transform: "scale(0.66)",
			},
			title: {
				...figmaHelper(14, 19),
				fontFamily: "sans",
				fontWeight: "semibold",
				color: "brand.blue",
				mb: 4,
			},
			backText: {
				...figmaHelper(14, 19),
				color: "black",
				hyphens: "auto",
			},
		};
	},
};

export default Flipper;
