
import ErrorPage from "src/app/components/ErrorPage.theme";
import ActionCard from "src/components/ActionCard/ActionCard.theme";
import AlertDialog from "src/components/AlertDialog/AlertDialog.theme";
import BackgroundShape from "src/components/BackgroundShape/BackgroundShape.theme";
import Benefit from "src/components/Benefit/Benefit.theme";
import BirthdateInput from "src/components/BirthdateInput/BirthdateInput.theme";
import Blogpost from "src/components/Blogpost/Blogpost.theme";
import Breadcrumbs from "src/components/Breadcrumbs/Breadcrumbs.theme";
import BubbleContainer from "src/components/BubbleContainer/BubbleContainer.theme";
import BulkPremiumCalcModal from "src/components/BulkPremiumCalcModal/BulkPremiumCalcModal.theme";
import BurgerButton from "src/components/BurgerButton/BurgerButton.theme";
import CMSButton from "src/components/CMSButton/CMSButton.theme";
import CancelationFeedback from "src/components/CancelationFeedback/CancelationFeedback.theme";
import Carousel from "src/components/Carousel/Carousel.theme";
import CmsMenuItem from "src/components/CmsMenuItem/CmsMenuItem.theme";
import Contact from "src/components/Contact/Contact.theme";
import ContextMenu from "src/components/ContextMenu/ContextMenu.theme";
import CreateClaimBrokerModal from "src/components/CreateClaimModal/CreateClaimBrokerModal.theme";
import CreateClaimModal from "src/components/CreateClaimModal/CreateClaimModal.theme";
import CustomerReviewSection from "src/components/CustomerReviewSection/CustomerReviewSection.theme";
import DashboardContent from "src/components/DashboardContent/DashboardContent.theme";
import DataTable from "src/components/DataTable/DataTable.theme";
import TableDetails from "src/components/DataTable/TableDetails/TableDetails.theme";
import DataTransferLoading from "src/components/DataTransferLoading/DataTransferLoading.theme";
import DatePicker from "src/components/DatePicker/DatePicker.theme";
import Document from "src/components/Document/Document.theme";
import DocumentList from "src/components/DocumentList/DocumentList.theme";
import EditableSection from "src/components/EditableSection/EditableSection.theme";
import EditorTools from "src/components/EditorTools/EditorTools.theme";
import FaqAccordion from "src/components/FaqAccordion/FaqAccordion.theme";
import Flipper from "src/components/Flipper/Flipper.theme";
import Footer from "src/components/Footer/Footer.theme";
import ForgotPasswordForm from "src/components/ForgotPasswordForm/ForgotPasswordForm.theme";
import FormErrorMessageI18n from "src/components/FormErrorMessageI18n/FormErrorMessageI18n.theme";
import FormSelect from "src/components/FormSelect/FormSelect.theme";
import DesktopMenu from "src/components/Header/DesktopMenu/DesktopMenu.theme";
import Header from "src/components/Header/Header.theme";
import MobileMenu from "src/components/Header/MobileMenu/MobileMenu.theme";
import HeaderDropdown from "src/components/HeaderDropdown/HeaderDropdown.theme";
import Heading from "src/components/Heading/Heading.theme";
import HeroBackLink from "src/components/HeroBackLink/HeroBackLink.theme";
import HeroSlider from "src/components/HeroSlider/HeroSlider.theme";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip.theme";
import InlineAsset from "src/components/InlineAsset/InlineAsset.theme";
import CheckmarkWrapper from "src/components/Inputs/CheckmarkWrapper.theme";
import InsurerName from "src/components/InsurerName/InsurerName.theme";
import LanguageSwitch from "src/components/LanguageSwitch/LanguageSwitch.theme";
import LinkCard from "src/components/LinkCard/LinkCard.theme";
import Loading from "src/components/Loading/Loading.theme";
import ConfirmTwoFactorForm from "src/components/LoginForm/ConfirmTwoFactorForm.theme";
import UsernamePasswordForm from "src/components/LoginForm/UsernamePasswordForm.theme";
import MissionSlider from "src/components/MissionSlider/MissionSlider.theme";
import Bell from "src/components/Notification/Bell.theme";
import Notification from "src/components/Notification/Notification.theme";
import OnboardingModal from "src/components/OnboardingModal/OnboardingModal.theme";
import PaymentMethodEmilEmbed from "src/components/PaymentMethodEmilEmbed/PaymentMethodEmilEmbed.theme";
import PolicyCard from "src/components/PolicyCard/PolicyCard.theme";
import PolicyDetails from "src/components/PolicyDetails/PolicyDetails.theme";
import PortalPolicyPricePreview from "src/components/PortalPolicyPricePreview/PortalPolicyPricePreview.theme";
import PremiumCalcModal from "src/components/PremiumCalcModal/PremiumCalcModal.theme";
import Product from "src/components/Product/Product.theme";
import ProductGroupPageContent from "src/components/ProductGroupPageContent/ProductGroupPageContent.theme";
import ProductOverview from "src/components/ProductOverview/ProductOverview.theme";
import RadioStack from "src/components/RadioStack/RadioStack.theme";
import RadioTabGroup from "src/components/RadioTabGroup/RadioTabGroup.theme";
import RouterIndicator from "src/components/RouterIndicator/RouterIndicator.theme";
import ScrollButton from "src/components/ScrollButton/ScrollButton.theme";
import SectionNavigator from "src/components/SectionNavigator/SectionNavigator.theme";
import SetPasswordForm from "src/components/SetPasswordForm/SetPasswordForm.theme";
import SpreadsheetUpload from "src/components/SpreadsheetUpload/SpreadsheetUpload.theme";
import StatusTag from "src/components/StatusTag/StatusTag.theme";
import Success from "src/components/Success/Success.theme";
import TabHero from "src/components/TabHero/TabHero.theme";
import TabsOrColumns from "src/components/TabsOrColumns/TabsOrColumns.theme";
import TextSkeleton from "src/components/TextSkeleton/TextSkeleton.theme";
import TopicSelector from "src/components/TopicSelector/TopicSelector.theme";
import UpdatePaymentIntervalModal from "src/components/UpdatePaymentIntervalModal/UpdatePaymentIntervalModal.theme";
import UpdatePolicyModal from "src/components/UpdatePolicyModal/UpdatePolicyModal.theme";
import UploadedFile from "src/components/UploadedFile/UploadedFile.theme";
import UserDataModal from "src/components/UserDataModal/UserDataModal.theme";
import UserOverview from "src/components/UserOverview/UserOverview.theme";
import VideoPlayer from "src/components/VideoPlayer/VideoPlayer.theme";
import ViewClaimModal from "src/components/ViewClaimModal/ViewClaimModal.theme";
import WithdrawPolicy from "src/components/WithdrawPolicyModal/WithdrawPolicy.theme";
import AboutSection from "src/sections/AboutSection/AboutSection.theme";
import BlogpostsSection from "src/sections/BlogpostsSection/BlogpostsSection.theme";
import CallToActionSection from "src/sections/CallToActionSection/CallToActionSection.theme";
import ContactForm from "src/sections/ContactForm/ContactForm.theme";
import FaqSection from "src/sections/FaqSection/FaqSection.theme";
import FlipperSection from "src/sections/FlipperSection/FlipperSection.theme";
import FunctionalitySection from "src/sections/FunctionalitySection/FunctionalitySection.theme";
import Hero from "src/sections/Hero/Hero.theme";
import ImageTextSection from "src/sections/ImageTextSection/ImageTextSection.theme";
import LinkCardsSection from "src/sections/LinkCardsSection/LinkCardsSection.theme";
import LogoWallSection from "src/sections/LogoWallSection/LogoWallSection.theme";
import Mission from "src/sections/Mission/Mission.theme";
import NavigationSection from "src/sections/NavigationSection/NavigationSection.theme";
import NewsletterSignup from "src/sections/NewsletterSignup/NewsletterSignup.theme";
import Offer from "src/sections/Offers/Offer.theme";
import Offers from "src/sections/Offers/Offers.theme";
import Persona from "src/sections/Persona/Persona.theme";
import PremiumCalcHero from "src/sections/PremiumCalcHero/PremiumCalcHero.theme";
import ProductComparison from "src/sections/ProductComparison/ProductComparison.theme";
import SectionRenderer from "src/sections/SectionRenderer.theme";
import ServiceBenefits from "src/sections/ServiceBenefits/ServiceBenefits.theme";
import TextSection from "src/sections/TextSection/TextSection.theme";
import UserActionSection from "src/sections/UserActionSection/UserActionSection.theme";
import VideoCollectionSection from "src/sections/VideoCollectionSection/VideoCollectionSection.theme";
import VideoSection from "src/sections/VideoSection/VideoSection.theme";
import Accordion from "src/styles/Accordion.theme";
import Alert from "src/styles/Alert.theme";
import Button from "src/styles/Button.theme";
import Checkbox from "src/styles/Checkbox.theme";
import Divider from "src/styles/Divider.theme";
import FormLabel from "src/styles/FormLabel.theme";
import Input from "src/styles/Input.theme";
import Link from "src/styles/Link.theme";
import Modal from "src/styles/Modal.theme";
import PinInput from "src/styles/PinInput.theme";
import Radio from "src/styles/Radio.theme";
import Select from "src/styles/Select.theme";
import Spinner from "src/styles/Spinner.theme";
import Table from "src/styles/Table.theme";
import Tabs from "src/styles/Tabs.theme";
import Tag from "src/styles/Tag.theme";
import Textarea from "src/styles/Textarea.theme";
import TextareaWithCounter from "src/styles/TextareaWithCounter.theme";
import Tooltip from "src/styles/Tooltip.theme";

export const components = {
	ErrorPage,
	ActionCard,
	AlertDialog,
	BackgroundShape,
	Benefit,
	BirthdateInput,
	Blogpost,
	Breadcrumbs,
	BubbleContainer,
	BulkPremiumCalcModal,
	BurgerButton,
	CMSButton,
	CancelationFeedback,
	Carousel,
	CmsMenuItem,
	Contact,
	ContextMenu,
	CreateClaimBrokerModal,
	CreateClaimModal,
	CustomerReviewSection,
	DashboardContent,
	DataTable,
	TableDetails,
	DataTransferLoading,
	DatePicker,
	Document,
	DocumentList,
	EditableSection,
	EditorTools,
	FaqAccordion,
	Flipper,
	Footer,
	ForgotPasswordForm,
	FormErrorMessageI18n,
	FormSelect,
	DesktopMenu,
	Header,
	MobileMenu,
	HeaderDropdown,
	Heading,
	HeroBackLink,
	HeroSlider,
	InfoTooltip,
	InlineAsset,
	CheckmarkWrapper,
	InsurerName,
	LanguageSwitch,
	LinkCard,
	Loading,
	ConfirmTwoFactorForm,
	UsernamePasswordForm,
	MissionSlider,
	Bell,
	Notification,
	OnboardingModal,
	PaymentMethodEmilEmbed,
	PolicyCard,
	PolicyDetails,
	PortalPolicyPricePreview,
	PremiumCalcModal,
	Product,
	ProductGroupPageContent,
	ProductOverview,
	RadioStack,
	RadioTabGroup,
	RouterIndicator,
	ScrollButton,
	SectionNavigator,
	SetPasswordForm,
	SpreadsheetUpload,
	StatusTag,
	Success,
	TabHero,
	TabsOrColumns,
	TextSkeleton,
	TopicSelector,
	UpdatePaymentIntervalModal,
	UpdatePolicyModal,
	UploadedFile,
	UserDataModal,
	UserOverview,
	VideoPlayer,
	ViewClaimModal,
	WithdrawPolicy,
	AboutSection,
	BlogpostsSection,
	CallToActionSection,
	ContactForm,
	FaqSection,
	FlipperSection,
	FunctionalitySection,
	Hero,
	ImageTextSection,
	LinkCardsSection,
	LogoWallSection,
	Mission,
	NavigationSection,
	NewsletterSignup,
	Offer,
	Offers,
	Persona,
	PremiumCalcHero,
	ProductComparison,
	SectionRenderer,
	ServiceBenefits,
	TextSection,
	UserActionSection,
	VideoCollectionSection,
	VideoSection,
	Accordion,
	Alert,
	Button,
	Checkbox,
	Divider,
	FormLabel,
	Input,
	Link,
	Modal,
	PinInput,
	Radio,
	Select,
	Spinner,
	Table,
	Tabs,
	Tag,
	Textarea,
	TextareaWithCounter,
	Tooltip,
}