import { figmaHelper } from "./typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const TextareaWithCounter: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			position: "relative",
		},
		counter: {
			textAlign: "right",
			...figmaHelper(14, 22),
			color: "brand.blue",
			position: "absolute",
			right: 0,
			top: "100%",
		},
		textarea: {
			_placeholder: {
				color: "ui.placeholder",
			},
		},
	},
};

export default TextareaWithCounter;
