import type { ComponentStyleConfig } from "@chakra-ui/theme";

const button = (transform?: string) => ({
	color: "brand.blue",
	textDecoration: "underline",
	fontWeight: "bold",

	svg: {
		color: "brand.highlight",
		transform,
	},
});

const FlipperSection: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			display: "flex",
			flexFlow: "column nowrap",
			justifyContent: "center",
			alignItems: "center",
		},
		grid: {
			width: "100%",
			listStyleType: "none",
		},

		intro: {
			mb: 4,
			textAlign: "center",
		},

		moreButton: {
			...button("rotate(180deg)"),
		},

		lessButton: {
			...button(),
		},
	},
};

export default FlipperSection;
