import { getInlineSvg } from "src/styles/getInlineSvg";
import { figmaHelper } from "src/styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const checkmarkSvg = (color: string) =>
	getInlineSvg(
		`<svg width="19" height="19" viewBox="0 0 19 19" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="m7.6 12.9 8.486-8.486 1.06 1.061-9.545 9.546-5.304-5.303 1.061-1.06L7.601 12.9Z" fill="${color}"/></svg>`,
	);

const FunctionalitySection: ComponentStyleConfig = {
	baseStyle: ({ desktopBreakpoint }) => ({
		wrapper: {
			paddingTop: { base: 12, [desktopBreakpoint]: 24 },
			bg: "brand.lighter",

			"[data-column-index='0']": {
				paddingRight: { base: 0, [desktopBreakpoint]: 12 },
			},

			"[data-column-index='1']": {
				paddingLeft: { base: 0, [desktopBreakpoint]: 12 },
				".functionality-section-item .chakra-heading:before": {
					backgroundImage: checkmarkSvg("#005092"),
				},
			},
		},
		intro: {
			textAlign: "center",
			paddingX: 4,
		},
		column: {
			listStyleType: "none",
			padding: 0,
		},
		item: {
			...figmaHelper(14, 19),
			py: 4,

			ul: {
				listStyleType: "disc",
			},
		},
		itemTitle: {
			...figmaHelper(16, 22),
			color: "brand.blue",
			mb: 3,
			display: "flex",

			span: {
				flex: "1",
			},
		},
	}),
};

export default FunctionalitySection;
