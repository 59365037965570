import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Breadcrumbs: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			maxW: "container.xl",
			margin: "auto",
			paddingY: { base: 4, lg: 6 },
			paddingRight: 6,
			ol: {
				paddingTop: 0,
				display: "flex",
				flexWrap: "wrap",

				li: {
					py: 2,
				},
			},
		},
		chevronIcon: {
			transform: "rotate(90deg)",
			width: 2,
		},
		breadcrumb: {
			color: "brand.blue",
			fontWeight: 500,
			"[aria-current='page']": {
				borderBottom: "1px solid",
				borderColor: "brand.highlight",
			},
		},
		homeIcon: {
			height: 6,
			width: 6,
		},
	},
};

export default Breadcrumbs;
