import { figmaHelper } from "src/styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const cellSpacing = { base: 2 };

const DataTable: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			fontFamily: "sans",
			overflowX: "auto",
		},
		tableWrapper: {
			display: "inline-block",
			minWidth: "100%",
		},
		table: {
			width: "100%",
		},
		head: {
			lineHeight: 4,
		},
		headRow: {
			th: {
				borderBottom: 0,
				background: "brand.lighter",
				fontFamily: "sans",
				fontWeight: "600",
				paddingY: 4,

				"&:first-of-type": {
					borderTopLeftRadius: "md",
					borderBottomLeftRadius: "md",
				},
				"&:last-of-type": {
					borderTopRightRadius: "md",
					borderBottomRightRadius: "md",
				},
			},
		},
		tableHeader: {
			textAlign: "center",
			lineHeight: 2,
			paddingX: cellSpacing,
		},
		header: {
			color: "brand.blue.darker",
			textTransform: "uppercase",
			fontWeight: "600",
			verticalAlign: "middle",
			mx: "auto",
		},
		statusDescription: {
			display: "inline-block",
			paddingBottom: 1,

			"> div > button": {
				padding: 0,
				marginX: 3,
			},
		},
		body: {
			w: "full",
		},
		row: {
			borderRadius: "md",
			...figmaHelper(14, 19),

			".ellipsis": {
				display: "block",
				textOverflow: "ellipsis",
				overflow: "hidden",
				whiteSpace: "nowrap",
				width: "20rem",
			},

			"&:nth-of-type(even)": {
				"th, td": {
					borderColor: "brand.lighter.translucent",
				},
				td: {
					borderBottom: 0,
					background: "brand.lighter.translucent",

					"&:first-of-type": {
						borderTopLeftRadius: "md",
						borderBottomLeftRadius: "md",
					},
					"&:last-of-type": {
						borderTopRightRadius: "md",
						borderBottomRightRadius: "md",
					},
				},
			},
		},
		cell: {
			border: 0,
			paddingX: cellSpacing,
		},
		expandedRow: {},
		expandedCell: {},
		globalSearch: {
			maxW: 96,
			marginBottom: 4,
		},
		pagination: {
			backgroundColor: "brand.lighter",
			borderRadius: "md",
			width: "full",
			display: "flex",
			justifyContent: "end",
			alignItems: "center",
			gap: 20,
			paddingX: 8,
			paddingY: 2,
			marginTop: 1,
			color: "brand.blue.darker",
			fontWeight: "600",
			fontSize: "sm",
		},
		sizeSelect: {
			display: "flex",
			alignItems: "center",
			flex: "1",
			justifyContent: "end",
			gap: 2,

			// chakra adds a div wrapper around their select component
			"> div": {
				width: 20,
			},

			Select: {
				backgroundColor: "brand.lighter",
				fontWeight: "600",
				fontSize: "sm",
			},
		},
		pageSelect: {
			display: "flex",
			alignItems: "baseline",

			paddingBottom: 1,
			span: {
				paddingX: 2,
			},
			svg: {
				color: "brand.medium",
			},
		},
		bulkContextWrapper: {
			display: "flex",
			alignItems: "center",
			gap: 5,
			whiteSpace: "pre",
			fontWeight: "semibold",
			fontSize: "sm",
			paddingTop: 4,
			paddingBottom: 4,
			width: "fit-content",
		},
		expanderButton: {
			cursor: "pointer",

			_disabled: {
				cursor: "not-allowed",
				color: "brand.light",
			},

			svg: {
				width: { base: 5, md: 6 },
				height: { base: 5, md: 6 },
			},
		},
	},
};

export default DataTable;
