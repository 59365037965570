import type { ComponentStyleConfig } from "@chakra-ui/react";

const CreateClaimBrokerModal: ComponentStyleConfig = {
	baseStyle: ({ isMobile }) => ({
		wrapper: {
			maxW: "2xl",
			mx: "auto",
			textAlign: "center",

			// adjust autofill styles for inputs (for them to not have a blue background)
			"input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
				{
					"-webkit-background-clip": "text",
					boxShadow: "inset 0 0 20px 20px white",
				},
		},
		submit: {
			minWidth: 80,
			alignSelf: "center",
		},

		fileUploadButton: {
			w: "full",
			maxW: "md",
			marginBottom: 2,
			bg: "white",
			border: "2px solid",
			color: "brand.highlight",
			borderColor: "brand.highlight",
			svg: {
				path: {
					fill: "brand.highlight",
				},
			},
			_hover: {
				bg: "white",
				color: "brand.highlight",
				svg: {
					path: {
						fill: "brand.highlight",
					},
				},
				cursor: "not-allowed",
			},
		},
		deleteFileButton: {
			bg: "brand.blue",
			_hover: { bg: "brand.highlight" },
			borderRadius: "50%",
		},
		note: {
			em: {
				color: "brand.highlight",
				fontWeight: 600,
				fontStyle: "normal",
			},
		},
		disabledInput: {
			":disabled": {
				borderColor: "brand.light",
				color: "brand.blue",
				opacity: 1,
			},
		},
		textarea: {
			bg: "white",
			border: "2px solid",
			borderColor: "brand.light",
		},
		selectIcon: {
			transform: "rotate(180deg)",
			path: {
				color: "brand.highlight",
			},
		},
	}),
	variants: {
		fileUploadEnabled: {
			fileUploadButton: {
				bg: "brand.highlight",
				color: "white",
				svg: {
					path: {
						fill: "white",
					},
				},
				cursor: "pointer",

				_hover: {
					bg: "brand.highlight.hover",
					borderColor: "brand.highlight.hover",
					color: "white",
					cursor: "pointer",
					svg: {
						path: {
							fill: "white",
						},
					},
				},
			},
		},
	},
};

export default CreateClaimBrokerModal;
