import { accordionAnatomy } from "@chakra-ui/anatomy";
import {
	createMultiStyleConfigHelpers,
	SystemStyleObject,
} from "@chakra-ui/react";
import { headerTypographyMixin } from "src/components/Header/Header.theme";
import { buttonHeightMixin } from "./Button.theme";
import { figmaHelper } from "./typography";

const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers(accordionAnatomy.keys);

const commonButtonStyles: SystemStyleObject = {
	...headerTypographyMixin,
	...buttonHeightMixin,
	pl: 4,
	pr: 4,
	justifyContent: "space-between",
	alignItems: "center",
	flexDirection: "row",
};

const headerPanel: SystemStyleObject = {
	// This matches the flex properties of the regular mobileMenu link list
	display: "flex",
	gap: 4,
	flexDirection: "column",

	paddingY: 0,
	paddingX: 0,
	paddingTop: 4,

	hr: {
		borderColor: "brand.medium",
		borderWidth: "0.5px",
	},

	"button, a": {
		_focus: {
			boxShadow: "none",
			color: "brand.highlight",
			textDecoration: "underline",
		},
	},

	button: {
		...headerTypographyMixin,
		background: "none",
		color: "var(--header-colors-primary, white)",
		textDecoration: "none",
		justifyContent: "start",
		pl: 8,
		width: "100%",
		height: "auto",
		_hover: {
			background: "none",
			color: "brand.highlight.hover",
		},
	},
};

const Accordion = defineMultiStyleConfig({
	baseStyle: definePartsStyle({
		container: {
			fontFamily: "sans",
			padding: 0,
			border: "none",
		},
	}),
	variants: {
		faq: definePartsStyle({
			panel: {
				...figmaHelper({ base: 14, md: 16 }, { base: 23, md: 24 }),
				paddingTop: {
					base: 6,
					md: 9,
				},
				paddingBottom: 0,
				paddingLeft: 0,

				"* + *": {
					marginTop: 4,
				},
			},
			button: {
				paddingLeft: 0,
				paddingRight: { base: 0, desktopNavigation: 4 },
				justifyContent: "space-between",
				alignItems: "start",
				fontFamily: "sans",
				flexDirection: {
					base: "column-reverse",
					md: "row",
				},
				gap: {
					base: 2,
					md: 0,
				},

				"&:hover": {
					color: "brand.highlight.hover",
					backgroundColor: "unset",
				},
			},
		}),
		header: definePartsStyle({
			button: {
				...commonButtonStyles,
				pl: 0,

				// We need a little bit of padding away from the white background edge
				transition: "padding 0.25s ease",
				"&[aria-expanded=true]": {
					backgroundColor: "white",
					color: "brand.blue",
					pl: 2,
				},
			},
			panel: {
				...headerPanel,
			},
		}),
		authHeader: definePartsStyle({
			button: {
				...commonButtonStyles,
				// ...buttonHeightMixin,

				background: "brand.highlight !important",
				color: "white !important",
				borderRadius: "md",
				_hover: {
					background: "brand.highlight.hover !important",
				},
			},
			panel: {
				...headerPanel,
			},
		}),
	},
});

export default Accordion;
