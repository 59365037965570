import type { ComponentStyleConfig } from "@chakra-ui/theme";

const VideoPlayer: ComponentStyleConfig = {
	baseStyle: {
		triggerButton: {
			border: "2px solid",
			borderColor: "brand.light",
			_after: {
				content: '""',
				width: { base: 8, md: 12 },
				height: { base: 8, md: 12 },
				display: "block",
				backgroundColor: "brand.blue",
				backgroundImage: "/**/url(/img/video-play.svg)",
				backgroundPosition: "60% 50%",
				backgroundRepeat: "no-repeat",
				backgroundSize: "auto 50%",
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				transitionProperty: "common",
				transitionDuration: "150ms",
				borderRadius: "full",
				pointerEvents: "none",
			},
			_hover: {
				_after: {
					transform: "translate(-50%, -50%) scale(1.2)",
				},
			},
			_focus: {
				_after: {
					boxShadow: "outline",
				},
			},
		},

		triggerImage: {
			width: "full",
			height: "full",
			transitionProperty: "common",
			transitionDuration: "300ms",
			transitionDelay: "150ms",

			"> img": {
				maxWidth: "none",
				height: "full",
				width: "full",
				objectFit: "cover",
			},

			_hover: {
				transform: "scale(1.05)",
			},
		},

		modalInner: {
			paddingX: { base: 0, md: 16, xl: 24 },
			paddingY: { base: 24, md: 0 },
		},
	},
};

export default VideoPlayer;
