import type { ComponentStyleConfig } from "@chakra-ui/theme";

const DatePicker: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			".rdp-root": {
				p: 4,
				"--rdp-font-family": "var(--chakra-fonts-sans)",
				"--rdp-weekday-opacity": "1",
				"--rdp-month_caption-font":
					"bold 1.125rem var(--rdp-font-family)",
				"--rdp-weekday-font": "bold 0.75rem var(--rdp-font-family)",
				"--rdp-day-height": "2.25rem",
				"--rdp-day-width": "2.25rem",

				".rdp-weekdays": {
					textTransform: "uppercase",
				},

				".rdp-chevron": {
					fill: "var(--chakra-colors-brand-blue)",
				},

				".rdp-day > .rdp-day_button": {
					border: "none",

					_hover: {
						bg: "var(--chakra-colors-brand-highlight-disabled)",
					},
				},

				".rdp-today > .rdp-day_button": {
					bg: "var(--chakra-colors-brand-highlight)",
					color: "white",
				},

				".rdp-dropdown_root": {
					p: 1,
				},
			},
		},
		dropdownWrapper: {
			_focusWithin: {
				outline: "2px solid",
				outlineColor: "brand.highlight",
				borderRadius: "sm",
			},
		},
	},
};

export default DatePicker;
