import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const SetPasswordForm: ComponentStyleConfig = {
	parts: ["wrapper", "title", "content", "form", "successIcon"],
	baseStyle: {
		wrapper: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
		},
		title: {
			paddingBottom: 6,
		},
		content: {
			...figmaHelper(16, 26),
			color: "brand.blue.darker",
			maxW: "container.sm",
			paddingBottom: 8,
		},
		form: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
		},
		successIcon: {
			height: 28,
			width: "100%",
			marginTop: 4,
			marginBottom: 10,
			color: "brand.highlight",
		},
		register: {
			margin: "0 auto",
			paddingTop: 4,
			textAlign: "center",
		},
	},
};

export default SetPasswordForm;
