import type { ComponentStyleConfig } from "@chakra-ui/theme";

const LinkCard: ComponentStyleConfig = {
	baseStyle: ({ variant }) => ({
		wrapper: {
			bg: "brand.lighter",
			w: "full",
			p: "1.25rem",
			display: "flex",
			alignItems: "center",
			flexDir: {
				base: "column",
				md: variant === "right" ? "row-reverse" : "row",
			},
			borderRadius: "6px",
		},
		imageWrapper: {
			px: "3.125rem",
			py: "1.5rem",
		},
		linkWrapper: {
			alignSelf: "flex-start",
			alignItems: "flex-start",
			w: "full",
			gap: 5,
		},
		grid: {
			w: "full",

			gap: 3,
			alignItems: "stretch",
		},
		link: {
			w: "full",
			bg: "brand.light",
			color: "brand.blue.darker",
			py: "0.625rem",
			px: 3,
			h: "full",
			minH: "3.75rem",

			_hover: {
				bg: "brand.blue",
				color: "white",
				"> div > span": {
					bg: "brand.blue.darker",
				},
			},
		},
		icon: {
			bg: "brand.lighter",
			w: "2.5rem",
			h: "2.5rem",
			borderRadius: "4px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexShrink: 0,

			svg: {
				w: "1.5rem",
				h: "1.5rem",
			},
		},
		subtitle: {
			fontSize: "sm",
			fontWeight: "400",
		},
		label: {
			gap: 0,
			alignItems: "flex-start",
			lineHeight: 1.3,
		},
	}),
};

export default LinkCard;
