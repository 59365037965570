import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { figmaHelper } from "./typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const faq = (props: StyleFunctionProps) => {
	return {
		tab: {
			...props.theme.components.Button.baseStyle,

			bg: "transparent",
			color: "brand.blue",

			border: "2px solid",
			height: 10,

			_hover: {
				color: "white",
				bg: "brand.blue",
				borderColor: "brand.blue",
				_disabled: {
					bg: "brand.blue.disabled",
				},
			},
			_selected: {
				bg: "brand.blue",
				color: "white",
				borderColor: "brand.blue",
			},
		},
		tabpanel: {
			padding: 0,
		},
		tablist: {
			flexWrap: "wrap",
			gap: 4,

			paddingTop: {
				base: 7,
				md: 5,
				lg: 0,
			},
		},
	};
};

const tabsOrColumns = (props: StyleFunctionProps) => {
	return {
		tab: {
			position: "relative",
			color: "brand.blue",
			...figmaHelper(18, 18),
			fontWeight: "bold",
			textShadow: "textDrop",
			border: "1.5px solid",
			borderRadius: "2xl",
			borderColor: "brand.highlight",

			_last: {
				borderColor: "brand.blue",
			},

			_hover: {
				bg: "brand.highlight.disabled",

				_last: {
					color: "white",
					bg: "brand.blue.dark",
					borderColor: "brand.blue.dark",
				},
			},

			_selected: {
				bg: "brand.highlight.faint",

				_last: {
					color: "white",
					bg: "brand.blue",
				},
			},
		},
		tabpanel: {
			padding: 0,
		},
		tablist: {
			flexWrap: "wrap",
			gap: 4,

			paddingTop: {
				base: 7,
				md: 5,
				lg: 0,
			},
		},
	};
};

const Tabs: ComponentStyleConfig = {
	defaultProps: {
		variant: "unstyled",
	},
	variants: {
		faq,
		tabsOrColumns,
	},
};

export default Tabs;
