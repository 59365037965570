import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers(tagAnatomy.keys);

export default defineMultiStyleConfig({
	variants: {
		fact: definePartsStyle({
			container: {
				alignItems: "flex-start",
				flexFlow: "row wrap",
				paddingX: 4,
				paddingY: 3,
				bg: "white",
				color: "brand.blue",

				"> *": {
					display: "contents",
				},
			},
		}),
	},
});
