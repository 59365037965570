import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { figmaHelper } from "../../styles/typography";

const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers([
		"wrapper",
		"media",
		"main",
		"inner",
		"content",
		"title",
		"subline",
		"actionsDesktop",
		"actionsMobile",
		"backLink",
		"text",
		"priceTag",
		"priceDescription",
	]);

export default defineMultiStyleConfig({
	baseStyle: definePartsStyle({
		wrapper: {
			position: "relative",
			overflow: "hidden",
		},
		inner: {
			display: "flex",
			gap: 5,
			paddingY: 6,
			paddingX: 2,
			justifyContent: "space-between",
			alignItems: "baseline",
			position: "relative",
			overflow: "hidden",
			height: "var(--hero-height)",
			minHeight: "var(--hero-min-height)",
			paddingTop: { base: 22, sm: 28, md: 32, lg: 28 },
		},
		media: {
			position: "absolute",
			zIndex: "hide",
			bottom: 0,
			right: { base: -4, md: 10, "2xl": "20vw" },
			width: { base: "xs", md: "sm", lg: "lg" },
		},
		content: {
			color: "white",
			width: { base: "full", lg: "4xl" },
			alignSelf: { base: "top", lg: "center" },
		},
		title: {
			...figmaHelper(
				{ base: 24, md: 48, "2xl": 64 },
				{ base: 32, md: 56, "2xl": 75 },
			),
			fontStyle: "italic",
			fontWeight: "normal",
			marginBottom: 12,
		},
		subline: {
			...figmaHelper(
				{ base: 18, md: 24, lg: 32 },
				{ base: 26, md: 32, lg: 42 },
			),
			width: { base: "full", lg: "2xl" },
			fontWeight: "bold",
			letterSpacing: "wide",
		},
		actionsMobile: {
			zIndex: 3,
			display: {
				base: "block",
				md: "none",
			},
			marginBottom: 4,
			"& > button, a": {
				width: "full",
			},
		},
		actionsDesktop: {
			zIndex: 3,
			display: {
				base: "none",
				md: "block",
			},
			marginTop: {
				md: 10,
				lg: 12,
			},
			"& > button, a": {
				minWidth: 80,
			},
		},
		backLink: {
			...figmaHelper(14, 21),
			marginY: { base: 0, lg: 8 },
			position: { base: "static", lg: "absolute" },
			top: { base: 0, lg: 20 },
			left: 0,
		},
		text: {
			li: {
				fontWeight: "bold",
				p: {
					fontSize: "lg",
				},
			},

			button: {
				padding: "0 4rem",
				width: { base: "full", sm: "unset" },
			},
		},
		priceTag: {
			position: "absolute",
			background: "brand.blue",
			top: "2rem",
			transform: "rotate(2.88deg)",
			padding: "0.5rem 0.75rem",
			borderRadius: "sm",
			fontSize: "2xl",
			width: "fit-content",
			boxShadow: "drop",
			display: { base: "none", lg: "block" },
		},
		priceDescription: {
			color: "white",
			strong: {
				fontSize: "3xl",
				fontWeight: "700",
			},
		},
	}),
	variants: {
		small: {
			inner: {
				height: "initial",
				minHeight: "29rem",
				paddingTop: { base: 10, sm: 12, md: 16, lg: 10 }, // Make room for navigation on top
			},
			media: {
				width: { base: "xs", sm: "sm", lg: "xl" },
				right: { base: -4, md: 10, "2xl": "20vw" },
			},
			content: {
				width: { base: "full", md: "34rem", lg: "34rem" },
			},
			title: {
				...figmaHelper(
					{ base: 20, md: 42, "2xl": 42 },
					{ base: 28, md: 56, "2xl": 56 },
				),
				marginTop: {
					base: 0,
					sm: 3,
					md: 0,
				},
				marginBottom: {
					base: 6,
					sm: 10,
				},
			},
			subline: {
				...figmaHelper(
					{ base: 18, md: 24, lg: 24 },
					{ base: 26, md: 30, lg: 30 },
				),
				width: "unset",
			},
			backLink: {
				position: "static",
			},
		},
		fade: {
			wrapper: {
				background:
					"linear-gradient(98.29deg, #005092 -4.74%, #005092 17.93%, transparent 77.13%)",
			},
			inner: {
				height: "initial",
				minHeight: "32rem",
				paddingX: 0,
			},
			content: {
				width: { base: "full", lg: "md", xl: "2xl" },
			},
			title: {
				fontStyle: "normal",
				fontFamily: "body",
				fontWeight: "bold",
				...figmaHelper(
					{
						base: 30,
						lg: 42,
						"2xl": 42,
					},
					{
						base: 40,
						lg: 52,
						"2xl": 52,
					},
				),
			},
			subline: {
				...figmaHelper(18, 26),
				width: "unset",
			},
			media: {
				right: 0,
				width: "full",
				height: "full",
			},
			backLink: {
				position: "static",
			},
		},
		slide: {
			wrapper: {
				bg: "brand.blue",
				display: "flex",
				flexDirection: {
					base: "column-reverse",
					lg: "row-reverse",
				},
				zIndex: "base",
				maxHeight: { lg: "85vh" },
			},
			main: {
				padding: 0,
				margin: 0,
				maxW: "none",
			},
			inner: {
				// These heights limit the size of the text content allowing the picture to grow on smaller viewports,
				// and keeps that empty space on larger viewports to vertically center the text.
				height: { base: "unset", lg: "full" },
				minHeight: { base: "unset" },
				marginBottom: {
					base: 4,
					lg: 0,
				},
				maxW: "none",
				marginLeft: {
					base: 0,
					// Spacing to clear the navigation arrows
					lg: 18,
					// Lines up with logo in header
					xl: "max(calc((100vw - var(--chakra-sizes-container-xl)) / 2), var(--chakra-space-18))",
				},
				paddingX: 0,
				marginTop: -4,
			},
			content: {
				width: "full",
				paddingLeft: { base: 6, xl: 4 },
				paddingRight: { base: 6, xl: 16 },
			},
			backLink: {
				position: "static",
			},
			title: {
				width: "unset",

				...figmaHelper(
					{ base: 26, md: 40, lg: 46, "2xl": 54 },
					{ base: 35, md: 52, lg: 56, "2xl": 68 },
				),
			},
			subline: {
				width: "unset",

				...figmaHelper(
					{ base: 18, md: 20, lg: 24 },
					{ base: 30, md: 28, lg: 30 },
				),
			},
			media: {
				position: "relative",
				right: 0,
				minWidth: { base: "full", md: "full", lg: "40vw" },
				minHeight: 0,
				maxHeight: { base: "50vh", lg: "unset" },
				alignSelf: "stretch",
			},
			actionsMobile: {
				position: "absolute",
				bottom: 4,
				left: 0,
				right: 0,
				marginX: 4,
			},
		},
		price: {
			wrapper: {
				bg: `radial-gradient(49.88% 47.92% at 51.74% 53.06%, #005092 29.69%, #021B47 95.11%)`,
				display: "flex",
				flexDirection: {
					base: "column",
					lg: "row-reverse",
				},
				zIndex: "base",
				maxHeight: { lg: "85vh" },
				borderBottomWidth: "1rem",
				borderColor: "var(--highlight-color)",
			},
			main: {
				padding: 0,
				margin: 0,
				maxW: "none",
			},
			inner: {
				// These heights limit the size of the text content allowing the picture to grow on smaller viewports,
				// and keeps that empty space on larger viewports to vertically center the text.
				height: { base: "unset", lg: "full" },
				minHeight: { base: "unset" },
				marginBottom: {
					base: 4,
					lg: 0,
				},
				maxW: "none",
				marginLeft: {
					base: 0,
					// Spacing to clear the navigation arrows
					lg: 18,
					// Lines up with logo in header
					xl: "max(calc((100vw - var(--chakra-sizes-container-xl)) / 2), var(--chakra-space-18))",
				},
				paddingX: 0,
				marginTop: -4,
			},
			content: {
				width: "full",
				maxWidth: { base: "full", md: "full", lg: "60vw" },
				paddingLeft: { base: 6, xl: 4 },
				paddingRight: { base: 6, xl: 16 },
				paddingBottom: "2.75rem",
			},
			backLink: {
				position: "static",
			},
			title: {
				width: "unset",

				...figmaHelper(
					{ base: 26, md: 40, lg: 46, "2xl": 54 },
					{ base: 35, md: 52, lg: 56, "2xl": 68 },
				),
			},
			subline: {
				width: "unset",

				...figmaHelper(
					{ base: 18, md: 20, lg: 24 },
					{ base: 30, md: 28, lg: 30 },
				),
			},
			media: {
				position: "relative",
				right: 0,
				minWidth: { base: "full", md: "full", lg: "40vw" },
				minHeight: 0,
				maxHeight: { base: "50vh", lg: "unset" },
				alignSelf: "stretch",
				display: "flex",
				justifyContent: "center",
			},
			actionsMobile: {
				position: "absolute",
				bottom: 4,
				left: 0,
				right: 0,
				marginX: 4,
			},
		},
	},
});
