import { sizes } from "src/components/Heading/Heading.theme";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const VideoSection: ComponentStyleConfig = {
	baseStyle: ({ breakpoint: bp, hasTopic }) => ({
		wrapper: {
			color: "brand.dark",
			py: 12,
		},
		intro: {
			px: "1.25rem",
			gap: 3,
			justifyContent: "center",
			textAlign: "center",
		},
		title: {
			fontSize: { base: "3xl", [bp]: "6xl" },
			fontStyle: "italic",
			maxW: "container.md",
		},
		imageWrapper: {
			maxH: "37.5rem",
			overflow: "hidden",
		},
		personaWrapper: {
			mx: "1.25rem",
			mb: 12,
			position: "relative",
			display: "flex",
			flexFlow: "column nowrap",
			alignItems: "center",
			bg: "white",
			boxShadow: "drop",
			borderTopRadius: "lg",
			marginTop: 16,
			textAlign: "center",
			paddingX: { base: 4 },
			paddingTop: { base: 8 },
			paddingBottom: { base: 6 },

			"h3, h4, h5": {
				...sizes.h2,
			},
			p: {
				marginBottom: "1em",
			},

			"> *": {
				maxWidth: "container.md",
			},

			_after: {
				content: "''",
				display: "block",
				position: "absolute",
				left: 0,
				top: "100%",
				height: 7,
				width: "full",
				bg: "var(--highlight-color)",
				borderBottomRadius: "lg",
			},
		},
		personaIcon: {
			marginTop: { base: -16, lg: -24 },
			marginBottom: 6,
			borderWidth: 6,
			borderColor: "brand.highlight",
			width: { base: 24, lg: 32 },
			height: { base: 24, lg: 32 },
			borderRadius: "2xl",
			bg: "white",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		innerWrapper: {
			display: "flex",
			justifyContent: "space-between",
		},
		topicWrapper: {
			alignItems: "flex-start",
			gap: 5,
		},
		text: {
			textAlign: hasTopic ? "left" : "center",
		},
	}),
	variants: {
		left: ({ breakpoint: bp }) => ({
			wrapper: {
				mx: "auto",
				maxW: "container.xl",
				gridTemplateColumns: { base: "1fr", [bp]: "1fr 1fr" },
				gap: { base: "2.5rem", [bp]: "7.5rem" },
			},
			imageWrapper: {
				gridColumn: 1,
				pr: { base: "1.25rem", [bp]: 0 },
				pl: "1.25rem",
			},
			innerWrapper: {
				flexDir: "column",
				gap: "2.5rem",
				gridColumn: { base: 1, [bp]: 2 },
				pr: "1.25rem",
				pl: { base: "1.25rem", [bp]: 0 },
			},
			intro: {
				gridColumn: { base: 1, [bp]: "1/3" },
			},
			personaWrapper: {
				gridColumn: { base: 1, [bp]: "1/3" },
			},
		}),
		top: ({ breakpoint: bp, fullBleed, hasTopic }) => ({
			wrapper: {
				gridTemplateColumns: "1fr min(80rem, 100%) 1fr",
				rowGap: { base: 10, [bp]: "3.75rem" },

				"> *": {
					gridColumn: 2,
				},
			},
			imageWrapper: {
				gridColumn: fullBleed ? "1/4" : undefined,
				mx: fullBleed ? undefined : "1.25rem",
			},
			innerWrapper: {
				flexDir: { base: "column", [bp]: "row" },
				gap: { base: "1.25rem", [bp]: "7.5rem" },
				px: "1.25rem",
			},
			topicWrapper: {
				maxW: { base: "full", [bp]: "50%" },
			},
			text: {
				maxW: hasTopic ? { base: "full", [bp]: "50%" } : "full",
			},
		}),
	},
};

export default VideoSection;
