import type { ComponentStyleConfig } from "@chakra-ui/theme";

const SpreadsheetUpload: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			color: "brand.blue",
		},
		formHelperText: {
			color: "brand.blue",
		},
		title: {
			textAlign: "center",
			paddingX: 5,
		},
		description: {
			marginTop: 7,
			marginBottom: 12,
			paddingX: 5,
		},
		stepTitle: {
			textAlign: "center",
			borderBottom: "1px solid",
			borderColor: "brand.light",
			width: "80%",
			marginX: "auto",
			paddingBottom: 3,
			marginBottom: 4,
		},
		buttonsWrapper: {
			display: "flex",
			justifyContent: "space-around",
			gap: 28,
			marginTop: 16,
		},
		stepColumns: {
			display: "flex",
			gap: 16,

			"> div": {
				flex: "1",
			},
		},
		imageWrapper: {
			display: "flex",
			justifyContent: "center",
		},
		fileInput: {
			marginTop: 10,
			display: "flex",
			justifyContent: "center",
		},
		stepButton: {
			w: 56,
			h: 10,
		},
		templateDownload: {
			display: "flex",
			justifyContent: "center",
			gap: 2,
			marginTop: 4,

			color: "brand.highlight",
			fontSize: "sm",

			"> span": {
				textDecoration: "underline",
			},
		},
		uploadedFile: {
			color: "#6F6F6F",
		},
	},
};

export default SpreadsheetUpload;
