import { ComponentStyleConfig } from "@chakra-ui/react";

const Textarea: ComponentStyleConfig = {
	variants: {
		filled: {
			background: "brand.lighter",
			color: "brand.blue",
			_focusVisible: {
				background: "brand.lighter",
			},
			_focus: {
				borderColor: "ui.focus",
			},
		},
		outline: {
			background: "brand.lighter",
		},
	},
	defaultProps: {
		variant: "filled",
	},
};

export default Textarea;
