import type { ComponentStyleConfig } from "@chakra-ui/theme";

const MobileMenu: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			display: {
				base: "block",
				desktopNavigation: "none",
			},
			paddingX: 2,
			paddingY: 4,
		},
		inner: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},
		drawerHeader: {
			"svg path": {
				fill: "white",
			},
		},
		drawer: {
			bg: "brand.blue",
			"--header-colors-primary": "white",
			overflow: "auto",
		},
		drawerBody: {
			maxW: "container.md",
			margin: "auto",
			display: "flex",
			flexDirection: "column",
			gap: 4,
			color: "white",
			paddingBottom: 16,
		},
	},
};

export default MobileMenu;
