import { figmaHelper } from "./typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const FormLabel: ComponentStyleConfig = {
	baseStyle: {
		color: "brand.blue",
		fontWeight: "semibold",
	},
	variants: {
		small: {
			...figmaHelper(14, 22),
			color: "brand.medium",
			fontWeight: "normal",
		},
	},
};

export default FormLabel;
