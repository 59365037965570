import { ComponentStyleConfig } from "@chakra-ui/react";
import { sizes } from "../../components/Heading/Heading.theme";
import { floatAnimation } from "../../styles/mixins";

const padding = { base: 9, lg: 16 };

const TextSection: ComponentStyleConfig = {
	parts: ["wrapper", "container", "arrow", "buttons"],
	baseStyle: {
		wrapper: {
			h2: { ...sizes.h3 },
			color: "brand.blue",
		},

		container: {
			padding,
			margin: "auto",
			maxW: "container.lg",

			"h1, h2, h3, h4, h5, h6": {
				maxW: "container.md",
			},
		},

		arrow: {
			display: "inline-block",
			color: "brand.light",
			marginTop: padding,
			...floatAnimation,

			svg: {
				height: 16,
				width: "auto",
			},
		},

		buttons: {
			"> [data-structured-text-block]": {
				"> *": {
					width: "full",
				},

				":not([data-structured-text-block='inline-asset-video'])": {
					maxW: "22rem",
				},
				marginX: "auto",
			},
		},
	},
	variants: {
		centered: {
			wrapper: {
				textAlign: "center",
			},

			container: {
				"h1, h2, h3, h4, h5, h6": {
					margin: "auto",
				},
			},
		},
		wide: {
			wrapper: {
				textAlign: "center",
			},
			container: {
				maxW: "container.xl",
			},
		},
		// Theoretically this should be more in line with the padding for the base variant, but it is most likely an edge case variant
		// Created for inserting a CMSLink at the top of a Fubis page without an hero.
		wideLeft: {
			container: {
				maxW: "container.xl",
				padding: 0,
				paddingY: { base: 2, lg: 6 },
			},
			arrow: {
				display: "block",
				textAlign: "center",
			},
		},
		// renders buttons inline; created to act as a document download section
		inline: {
			container: {
				textAlign: "center",
				padding: { base: 4, lg: 8 },
				fontWeight: "500",

				"> *": {
					":not(:last-child)": {
						marginRight: 1,
					},
					display: "inline-block",
				},
				p: {
					marginBottom: 0,
				},
				a: {
					textDecoration: "underline",
				},

				// prevent the rendering of the "external link" icon
				svg: {
					"&:last-of-type": {
						display: "none",
					},
				},
			},
		},
	},
};

export default TextSection;
