import type { ComponentStyleConfig } from "@chakra-ui/theme";

const LogoWallSection: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			paddingTop: { base: 10, lg: 16 },
			paddingBottom: { base: 10, lg: 20 },
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			backgroundColor: "brand.blue",
		},
		title: {
			color: "white",
		},
		logoGrid: {
			paddingTop: { base: 12, lg: 18 },
		},
		logoWrapper: {
			height: 24,
			width: 24,
			overflow: "hidden",
			display: "flex",
			justifyContent: "center",
			borderRadius: "50%",
		},
		logo: {
			display: "block",
			width: "100%",
		},
	},
};

export default LogoWallSection;
