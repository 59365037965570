import { figmaHelper } from "src/styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const ErrorPage: ComponentStyleConfig = {
	parts: [
		"container",
		"centerWrapper",
		"headline",
		"description",
		"link",
		"icon",
	],

	baseStyle: {
		container: {
			flex: "1",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			height: "100%",

			paddingBottom: 20,
			paddingTop: {
				base: 2.5,
				md: 0,
			},
		},
		centerWrapper: {
			height: "100%",
			textAlign: "center",
		},
		headline: {
			...figmaHelper({ base: 64, lg: 98 }, { base: 87, lg: 134 }),
			fontWeight: "bold",
			color: "brand.blue",
		},
		description: {
			paddingTop: {
				base: 5,
				lg: 9,
			},
			paddingBottom: {
				base: 8,
				lg: 5,
			},
			"& p": {
				margin: "0 auto",
			},
		},
		link: {
			color: "brand.highlight",
			fontWeight: "bold",
		},
		icon: {
			width: 40,
			height: 44,

			position: "relative",
			top: 6,
		},
	},
};

export default ErrorPage;
