import { createMultiStyleConfigHelpers, cssVar } from "@chakra-ui/react";

const columnColor = cssVar("vcs-column-color");

const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers([
		"wrapper",
		"intro",
		"items",
		"item",
		"itemTitle",
		"subline",
	]);

export default defineMultiStyleConfig({
	baseStyle: definePartsStyle(({ desktopBreakpoint }) => {
		return {
			wrapper: {
				paddingBottom: 16,

				"[data-column-index='0']": {
					paddingRight: { base: 0, [desktopBreakpoint]: 12 },
				},

				"[data-column-index='1']": {
					paddingLeft: { base: 0, [desktopBreakpoint]: 12 },
				},
			},
			intro: {
				textAlign: "center",
			},
			item: {
				paddingBottom: { base: 10, [desktopBreakpoint]: 32 },

				".video-player-trigger": {
					_after: {
						backgroundColor: columnColor.reference,
					},
				},
			},
			itemTitle: {
				paddingBottom: 3,
				color: "brand.blue",
			},

			subline: {
				paddingBottom: 3,
			},
		};
	}),
});
