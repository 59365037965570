import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Modal: ComponentStyleConfig = {
	variants: {
		funk: {
			closeButton: {
				background: "brand.blue",
				color: "white",
				borderRadius: "full",
				width: { base: "var(--close-button-size)", lg: 14 },
				height: { base: "var(--close-button-size)", lg: 14 },
				top: 4,
				right: 4,
				zIndex: "modal",
				svg: {
					transform: { base: "scale(0.9)", lg: "scale(1.3)" },
				},
				_hover: {
					background: "brand.highlight",
				},
				_focus: {
					background: "brand.highlight",
					boxShadow: "focusNoPadding",
				},
			},
			body: {
				marginBottom: 20,
			},
			footer: {},
		},
	},
};

export default Modal;
