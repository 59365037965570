import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Checkbox: ComponentStyleConfig = {
	baseStyle: {
		control: {
			marginStart: 4,
			transitionProperty: "common",
			transitionDuration: "300ms",
			borderColor: "brand.highlight",
			borderRadius: "md",
			padding: 3,
			_checked: {
				bg: "rgba(81, 197, 25, 0.15);",
				borderColor: "brand.highlight",
				color: "brand.highlight",
				_disabled: {
					bg: "gray.medium",
					borderColor: "gray.dark",
					color: "brand.highlight",
				},
				_hover: {
					borderColor: "brand.highlight",
					bg: "brand.highlight.disabled",
				},
			},
			_hover: {
				borderColor: "brand.highlight",
				bg: "brand.highlight.disabled",
			},
			_disabled: {
				bg: "gray.medium",
				borderColor: "gray.dark",
				color: "gray.medium",
			},
		},
	},
	sizes: {
		md: { icon: { fontSize: "lg" } },
	},
	variants: {
		table: {
			control: {
				borderColor: "brand.blue.darker",

				padding: 0,
				width: { base: 5, md: 7 },
				height: { base: 5, md: 7 },

				_checked: {
					bg: "rgba(0, 80, 146, 0.15)",
					borderColor: "brand.blue.darker",
					color: "brand.blue.darker",
					_disabled: {
						bg: "gray.medium",
						borderColor: "gray.dark",
						color: "brand.blue.darker",
					},
					_hover: {
						borderColor: "brand.highlight",
						bg: "brand.highlight.disabled",
					},
				},
				_hover: {
					borderColor: "brand.highlight",
					bg: "brand.highlight.disabled",
				},
				_disabled: {
					bg: "unset",
					borderColor: "rgba(0, 80, 146, 0.15)",
					color: "gray.medium",
				},
			},
			icon: {
				strokeWidth: {
					base: "1.5px !important",
					md: "1.25px !important",
				},
				width: { base: "1em !important", md: "1.2em !important" },
			},
		},
	},
};

export default Checkbox;
