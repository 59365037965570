import { fillParent } from "src/styles/mixins";
import { getChakraColumnWidth } from "src/styles/themeUtils";
import { figmaHelper } from "src/styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const ImageTextSection: ComponentStyleConfig = {
	baseStyle: ({ desktopBreakpoint: bp }) => ({
		wrapper: {
			borderBottomWidth: { base: 0, [bp]: "1rem" },
			borderColor: "var(--highlight-color)",
			bg: "brand.blue",
			maxW: "full",
			padding: 0,
		},
		grid: {
			margin: "auto",
		},
		textOuter: {
			display: "flex",
			justifyContent: { base: "stretch", sm: "flex-end" },
			textAlign: { base: "center", [bp]: "left" },
			order: { base: 1, [bp]: 0 },
			marginX: { base: 4, [bp]: 0 },
			minHeight: "20rem",
		},
		text: {
			maxWidth: { base: "none", [bp]: getChakraColumnWidth(6) },

			color: "white",
			hyphens: { base: "auto", sm: "none" },

			paddingY: { base: 8, [bp]: 16 },
			paddingLeft: { base: 4, xl: 0 },
			paddingRight: 4,

			h2: {
				color: "white",
			},

			p: {
				...figmaHelper(16, 26),
			},

			'[data-structured-text-block="link-button"]': {
				marginTop: 8,
				"> *": {
					minWidth: { base: "full", [bp]: "20rem" },
				},
			},
		},

		imageWrapper: {
			position: "relative",
			minHeight: "20rem",
		},

		image: {
			...fillParent,
		},
	}),
};

export default ImageTextSection;
