import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const UserDataModal: ComponentStyleConfig = {
	baseStyle: {
		formGroupRead: {
			display: "flex",
			alignItems: "center",
			flexDirection: {
				base: "column",
				sm: "row",
			},

			...figmaHelper(14, 22),
			fontWeight: "medium",
			color: "brand.blue.darker",

			"> div:nth-of-type(2n + 1)": {
				flex: "1",
			},
			"> div:nth-of-type(2n)": {
				flex: "2",
			},
		},
		formGroupEdit: {
			display: "flex",
			flexDirection: "column",
			gap: 2,
			paddingTop: 6,
			...figmaHelper(14, 22),
			fontWeight: "medium",
			color: "brand.blue.darker",
		},
		formGroupLabel: {
			margin: 0,
			color: "brand.blue",
			fontWeight: "inherit",
			...figmaHelper(14, 22),
		},
		formGroupInput: {
			fontSize: "inherit",
			lineHeight: "inherit",
			fontWeight: "inherit",
		},
		customError: {
			listStyle: "none",
			color: "ui.error",
			fontSize: "sm",
			fontWeight: "medium",
		},
		errorMessage: {
			my: 0,
		},
		genderHeading: {
			margin: 0,
			textAlign: "center",
			color: "brand.medium",
			fontWeight: "medium",
			...figmaHelper(14, 22),
		},
	},
};

export default UserDataModal;
