import { priceStyle } from "../Product/Product.theme";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const ProductOverview: ComponentStyleConfig = {
	baseStyle: ({ hasBookingFunnel, introAlignment }) => ({
		intro: {
			paddingY: 2,
			maxWidth: "container.lg",
			margin: "auto",
			textAlign: [introAlignment],

			"h1, h2, h3, h4, h5, h6": {
				maxW: "container.md",
				margin: "auto",
			},
		},
		grid: {
			gap: "grid.gap",
		},
		gridItem: {
			boxShadow: "drop",
			bg: "white",
			display: "flex",
			flexDir: "column",
			borderRadius: "md",
			overflow: "hidden",
			transitionProperty: "common",
			transitionDuration: "300ms",
			color: "brand.blue",

			svg: {
				transition: "opacity 0s ease-in",
				opacity: 0,
			},

			_hover: {
				boxShadow:
					"var(--chakra-shadows-pop), var(--chakra-shadows-focusNoPadding)",
				svg: {
					opacity: 1,
					transition: "opacity 300ms ease-out",
				},
			},
		},
		textWrapper: {
			padding: "3rem 1rem",
			display: "flex",
			flexDir: "column",
			gap: "1rem",
			flexGrow: 1,
		},
		bottomWrapper: {
			width: "full",
			display: "flex",
			flexDir: "row",
			minH: "4rem",
			marginTop: "auto",
		},
		price: {
			strong: {
				...priceStyle,
			},
			flexGrow: "1",
			display: "flex",
			alignItems: "center",
		},
		link: {
			color: "brand.highlight",
			alignSelf: "center",
		},
		externalLink: {
			textDecor: "none",
			alignSelf: "center",
			w: "full",
			display: "flex",
		},
		externalLinkText: {
			paddingBottom: "0.5rem",
			borderBottom: "1px solid",
			borderColor: "brand.highlight",
		},
		arrow: {
			width: "2rem",
			height: "2rem",
			color: "brand.highlight",
			ml: "auto",
		},
		productType: {
			bg: hasBookingFunnel ? "brand.blue" : "brand.light",
			color: hasBookingFunnel ? "white" : "brand.blue",
			fontWeight: "semibold",
			textAlign: "center",
			paddingY: "0.5rem",
		},
	}),
};

export default ProductOverview;
