import { ComponentStyleConfig } from "@chakra-ui/react";

const dotSize = 3;
const dotPadding = 1.5;

const SectionNavigator: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			display: { base: "none", xl: "block" },
			position: "fixed",
			zIndex: 3,
			top: "50%",
			left: 10,
			width: dotPadding + dotSize + dotPadding,
			transform: "translateY(-50%)",
		},
		item: {
			position: "relative",
			display: "block",
			padding: dotPadding,
			width: dotPadding + dotSize + dotPadding,
			height: dotPadding + dotSize + dotPadding,
			borderRadius: "full",

			_after: {
				position: "absolute",
				content: "''",
				display: "block",
				left: "50%",
				top: "50%",
				bg: "brand.lighter",
				border: "1px solid",
				borderColor: "brand.medium",
				width: dotSize,
				height: dotSize,
				borderRadius: "full",
				transform: "translate(-50%, -50%)",
				transitionProperty: "common",
				transitionDuration: "300ms",
			},

			_hover: {
				_after: {
					transform: "translate(-50%, -50%) scale(1.5)",
					bg: "brand.light",
				},
			},

			"&.is-selected": {
				_after: {
					bg: "brand.highlight",
					borderColor: "brand.highlight",
				},
			},
		},
	},
};

export default SectionNavigator;
